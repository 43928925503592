import clsx from 'clsx'

interface Props {
  className?: string
  colors?: {
    talks?: string
    tread?: string
  }
}

const defaultColors = {
  talks: 'text-gray-900',
  tread: 'text-primary-600',
}

function Logo({ className, colors = defaultColors }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      viewBox="0 0 398.3 53.7"
      className={clsx('fill-current', className)}
    >
      <g className={clsx('fill-current', colors.tread)}>
        <path d="M0 12.5h14.5v39.7h14.1V12.5h14.5V0H0zM88.9 41.4v-3.2c0-6-1.1-7.4-2-8.5-.9-1.1-1.9-1.8-3.3-2.4 1.4-1 2.5-2.3 3.3-3.9 1.3-2.5 2-5.1 2-7.7 0-2.1-.3-4.1-1-5.8-.7-1.7-1.6-3.2-2.7-4.6-1.4-1.7-3.2-3-5.3-3.9-2-.9-4.8-1.3-8.4-1.4H46.3v52.2h13.8V33.3H69c4.3-.1 5.7 1.6 5.7 5.4v13.2h14.1c.1 0 .1-7.8.1-10.5zM73 20.7c-.5.3-1.7.6-4 .6h-8.8v-9h9.1c2.1 0 3.2.3 3.7.6.4.2 1.7.9 1.7 4.1 0 2.6-1 3.4-1.7 3.7zM109.8 31.2h23.4V18.8h-23.4v-6.3h25.5V0H95.9v52.2h40.6V39.6h-26.7zM234.8 16.5c-.7-3-1.9-5.9-3.8-8.5-2.3-3.3-5.4-5.6-9.2-6.9-2.1-.7-4.8-1.1-8-1.1H191v52.2h22.8c8 0 14-3.4 17.9-10 2.7-4.7 4.1-10.6 4.1-17.3 0-2.6-.3-5.5-1-8.4zm-14.6 17.6c-1.6 3.9-4.2 5.8-8.1 5.8h-7.3V12.4h7.3c3.9 0 6.3 1 7.5 3 1.4 2.3 2 5.8 2 10.3 0 3.2-.5 6-1.4 8.4z" />
        <path d="M170.9 0h-14.2l-18.6 52.2h14.8l7.6-23.1h-6V23h6v-5.7h6.2V23h5.9v6.1h-5.9l7.5 23.1h15.2z" />
      </g>
      <g className={clsx('fill-current', colors.talks)}>
        <path d="M282.4.6v6.2H265v45.5h-7.1V6.7h-17.4V.6h41.9zM299.4 29.4c1.5-.2 2.4-.8 2.9-1.8.3-.6.4-1.4.4-2.4 0-2.2-.8-3.7-2.3-4.7-1.5-1-3.7-1.5-6.6-1.5-3.3 0-5.6.9-7 2.7-.8 1-1.3 2.5-1.5 4.4h-5.9c.1-4.7 1.6-7.9 4.5-9.7 2.9-1.8 6.2-2.7 10.1-2.7 4.4 0 8 .8 10.8 2.5 2.7 1.7 4.1 4.3 4.1 7.9v21.7c0 .7.1 1.2.4 1.6.3.4.8.6 1.7.6.3 0 .6 0 .9-.1s.7-.1 1.1-.2v4.7c-1 .3-1.7.5-2.2.5-.5.1-1.2.1-2.1.1-2.2 0-3.8-.8-4.7-2.3-.5-.8-.9-2-1.1-3.5-1.3 1.7-3.1 3.2-5.6 4.4a17.8 17.8 0 01-8 1.9c-3.5 0-6.3-1.1-8.6-3.2s-3.3-4.8-3.3-8c0-3.5 1.1-6.2 3.3-8.1 2.2-1.9 5-3.1 8.6-3.5l10.1-1.3zm-13.3 17.1c1.3 1.1 2.9 1.6 4.7 1.6 2.2 0 4.4-.5 6.5-1.5 3.5-1.7 5.3-4.5 5.3-8.4v-5.1c-.8.5-1.8.9-3 1.2-1.2.3-2.4.6-3.6.7l-3.8.5c-2.3.3-4 .8-5.2 1.4-2 1.1-2.9 2.9-2.9 5.3 0 1.8.7 3.3 2 4.3zM318.7.6h6.3v51.6h-6.3V.6zM333.7.6h6.1v30l16.2-16h8.1l-14.5 14 15.3 23.7h-8.1L345 33.1l-5.2 5v14.1h-6.1V.6zM372.9 40.4c.2 2.1.7 3.7 1.6 4.9 1.6 2 4.5 3.1 8.5 3.1 2.4 0 4.5-.5 6.3-1.5 1.8-1 2.7-2.6 2.7-4.7 0-1.6-.7-2.8-2.2-3.7-.9-.5-2.8-1.1-5.5-1.8l-5.1-1.3c-3.3-.8-5.7-1.7-7.2-2.7-2.8-1.7-4.1-4.1-4.1-7.1 0-3.6 1.3-6.4 3.9-8.6 2.6-2.2 6.1-3.3 10.5-3.3 5.7 0 9.9 1.7 12.4 5 1.6 2.1 2.4 4.4 2.3 6.8h-6c-.1-1.4-.6-2.7-1.5-3.9-1.5-1.7-4.1-2.5-7.7-2.5-2.4 0-4.3.5-5.6 1.4s-1.9 2.1-1.9 3.6c0 1.6.8 2.9 2.5 3.9 1 .6 2.4 1.1 4.2 1.5l4.3 1c4.6 1.1 7.7 2.2 9.3 3.2 2.5 1.6 3.7 4.2 3.7 7.6 0 3.4-1.3 6.2-3.9 8.7-2.6 2.4-6.5 3.7-11.8 3.7-5.7 0-9.7-1.3-12-3.8-2.4-2.5-3.6-5.7-3.8-9.4h6.1z" />
      </g>
    </svg>
  )
}

export { Logo }
